/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*--------------------Heights hoverboxes--------------------*/
.hoverbox {min-height:20vw; overflow:hidden; } 
.container .hoverbox { min-height:calc(1100px / 4.5); }
.container.lrg .hoverbox { min-height:calc(1300px / 4.5); }


/*General*/
.hoverboxes {padding: 40px 0; }
.hoverboxes, .hoverbox { position: relative; cursor: pointer; }
.hoverbox-image { position:absolute; width:100%; height:100%; z-index:0; background-size:cover; background-position: center center; }
.hoverbox-text {position: absolute; width: 100%; height: 100%; left: 0;  top: 0; color: #fff; padding: 2em; text-shadow: 0 0 15px rgba(0, 0, 0, 0.4); 
 display: flex;  align-items: center; flex-direction: column; justify-content: center; text-align:center; }
.hoverbox-text > a { position: absolute; width: 100%; height: 100%; left: 0;  top: 0; font-size: 0; opacity: 0; z-index: 10;}
.hoverbox-text h2 {text-transform:uppercase; font-weight:400; }
.hoverbox-text p {margin:0; max-width:100%;  line-height: 1.1em; }



/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/

/*---------------------------FADE boxar------------------------------*/
.hoverbox-text {background: rgba(0, 0, 0, 0); transition: background 0.5s ease 0s;}
.fade:hover .hoverbox-text { background: rgba(0, 0, 0, 0.5); transition: background 0.5s ease 0s;}
.fade h2 {padding: 0 0 5px;}
.fade p { opacity: 0; transition: opacity 0.5s ease 0s;}
.fade:hover p {opacity: 1; transition: opacity 0.5s ease 0s;}
 
/*---------------------------BOTTOM BAR white------------------------------*/
.bottombar .hoverbox-text { background: #fff; bottom: -1px; color:#404040;   height: auto; padding: 15px 20px; top: auto; text-shadow:none; 
transform: translate3d(0px, 100%, 0px); transition: transform 0.35s ease 0s;}
.bottombar:hover .hoverbox-text {  transform: translate3d(0px, 0px, 0px);}
.bottombar h2 { transform: translate3d(0px, 200%, 0px); transition: transform 0.35s ease 0s;}
.bottombar:hover h2 { transition-delay: 0.05s; transform: translate3d(0px, 0px, 0px);}
.bottombar p {display:none; }
 
/*---------------------------SLIDE IN  -----------------------------*/
.slidein .hoverbox-image {width:105%; background-position: -10px center; transition: 0.2s ease 0s;}
.slidein:hover .hoverbox-image { background-position:  0px center;  transition: 0.2s ease 0s;  }
.slidein .hoverbox-text { text-align: left; background:rgba(0, 0, 0, 0); display:block; align-items: stretch; flex-direction: row; justify-content: flex-start;  transition:background 0.2s ease 0s;  }
.slidein:hover .hoverbox-text {background:rgba(0, 0, 0, 0.3); transition:background 0.2s ease 0s;  }
.slidein h2 { overflow: hidden; padding: 0 0 10px; position: relative;}
.slidein h2:after {content: ""; position: absolute; background: #fff; bottom: 0; height: 3px; left: 0; transform: translate3d(-100%, 0px, 0px); transition: transform 0.35s ease 0s; width: 100%;}
.slidein:hover h2:after { transform: translate3d(0px, 0px, 0px);}
.slidein p { opacity: 0; padding: 10px 0; transform: translate3d(100%, 0px, 0px); transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;}
.slidein:hover p {opacity: 1; transform: translate3d(0px, 0px, 0px);}


/*---------------------------CENTERBOX med border-----------------------------*/
.centerbox {background:#000; }
.centerbox img {opacity: 0.9; transition: opacity 0.35s ease 0s;}
.centerbox:hover img {opacity: 0.4;}
.centerbox .hoverbox-text {padding: 35px;}
.centerbox:hover .hoverbox-text { background-color: rgba(0, 0, 0, 0);}
.centerbox .hoverbox-text:before { content: ""; position: absolute; right: 20px; left: 20px; top: 20px; bottom: 20px; opacity: 0;
border: 2px solid #fff; background:rgba(0, 0, 0, 0.4);  box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.2); transform: scale3d(1.4, 1.4, 1); transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;}
.centerbox:hover .hoverbox-text:before  { opacity: 1;transform: scale3d(1, 1, 1);}
.centerbox h2 {position:relative; }
.centerbox p { opacity: 0; transform: scale(1.5);transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;}
.centerbox:hover p { opacity: 1; transform: scale3d(1, 1, 1);}



/*---------------------------LINESLIDES ----------------------------*/
.lineslides {/*set background to make tone*/}
.lineslides.hoverbox-image {transform: scale(1); transition: transform 0.3s ease-in 0s;}
.lineslides:hover .hoverbox-image {transform: scale(1.05); transition: transform 0.3s ease-in 0s;}
.lineslides .hoverbox-text:after {content: ""; position: absolute; width: 100%; height: 100%; top: 0; left: 0; opacity: 0; 
 border-bottom: 2px solid #fff; border-top: 2px solid #fff; transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1); transform-origin: 50% 50% 0;  transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;}
.lineslides:hover .hoverbox-text:after { opacity: 1;transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1);}
.lineslides h2 {opacity: 1; }
.lineslides p { opacity: 0; padding: 0.5em 2em; text-transform: none; opacity: 0; transform: scale3d(0.8, 0.8, 1); transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;}
.lineslides:hover p { opacity: 1; transform: scale3d(1, 1, 1);}

/*---------------------------WHITEBOX dropdown ruta m text----------------------------*/
 .whitebox .hoverbox-text {text-align:left; z-index: 1; display:block; align-items: stretch; flex-direction: row; justify-content: flex-start; text-shadow:none; }
.whitebox .hoverbox-text:before { content: "";  position: absolute; top: 0; left: 2em; right: 2em; height: 50%; background: #fff; 
 transform: translate3d(0px, 4.5em, 0px) scale3d(1, 0, 1);transform-origin: 50% 0 0;transition: opacity 0.35s ease 0s, transform 0.35s ease 0s; z-index: -1;}
.whitebox:hover .hoverbox-text:before { opacity: 0.7; transform: translate3d(0px, 4.5em, 0px) scale3d(1, 1, 1);}
.whitebox p {color: #404040; opacity: 0;  padding: 2em 1em; transform: translate3d(0px, -10px, 0px); transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;}
.whitebox:hover p {opacity: 1;transform: translate3d(0px, 0px, 0px);}
.whitebox p a {color: #404040; margin: 0 0.5em;}
.whitebox p a:hover, .whitebox p a:focus  { opacity: 0.6;}

/*---------------------------DIGONASALIDES i fade vitt---------------------------*/
.diagonalslides .hoverbox-image {transform: scale(1); transition: transform 0.3s ease-in 0s;}
.diagonalslides:hover .hoverbox-image {transform: scale(1.05); transition: transform 0.3s ease-in 0s;}
.diagonalslides .hoverbox-text:before { background: rgba(255, 255, 255, 0.5); content: "";height: 100%;left: 0; position: absolute;top: 0;
transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0px, -100%, 0px); transition: transform 0.6s ease 0s;width: 100%;}
.diagonalslides:hover .hoverbox-text:before { transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0px, 100%, 0px);}
.diagonalslides h2 {padding:0 0 30px; }
.diagonalslides p {display:none}

/*---------------------------FATBORDER i vitt & opacity på text--------------------------*/
.fatborder {background: #fff;}
.fatborder .hoverbox-image {transform: scale(1); transition: transform 0.3s ease-in 0s;}
.fatborder:hover .hoverbox-image {transform: scale(0.95,0.93); transition: transform 0.3s ease-in 0s;}
.fatborder .hoverbox-text {padding: 2em 2em 4em; } 
.fatborder h2 {transform: translate3d(0px, 20px, 0px); transition: transform 0.35s ease 0s;}
.fatborder:hover h2 { transform: translate3d(0px, 0px, 0px);}
.fatborder p {opacity: 0; transform: perspective(1000px) rotate3d(1, 0, 0, 90deg);transform-origin: 50% 0 0; transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;}
.fatborder:hover p {opacity: 1;transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);}




 

 


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
.hoverbox {min-height:23vw !important}
}


/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{

}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
.hoverbox {min-height:30vw !important;}
    
.bottombar .hoverbox-text { transform: none !important; background: rgba(255, 255, 255, 0.8); }
.bottombar h2 { transform: none !important;}
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{
.hoverbox {min-height:50vw !important;} 
.hoverboxes {padding: 10px 0; }   
.hoverbox {margin-bottom: 10px; }  
    
}

@media (max-width: 350px)
{

}

/*-------------------------------------------------------------------END MEDIA QUERYS--------------------------------------------------------------------*/
